import FooterOptions from '@/components/FooterOptions';
import React, { FC, ReactElement } from 'react';

import './Cookies.scss';

const Cookies: FC = (): ReactElement => (
  <>
    <FooterOptions
      className="cookies footerOptions"
      Header="The Motokiki Cookies Policy"
      SubHeader="Thank you for displaying interest in our company and our website."
      SubHeader2="We at Motokiki take the protection of your personal data and privacy very seriously."
    />

    <div className="cookiesPageContainer">
      <h2>What is a cookie?</h2>
      <p>
        A cookie is a small text file that may be placed on your device when you
        visit our website. When you next visit our website the cookie allows us
        to distinguish you from other users.
      </p>
      <p>There are two broad categories of cookies:</p>
      <p>
        <em>
          <strong>Persistent cookies</strong>
        </em>
      </p>
      <p>
        Persistent cookies remain on your device until deleted manually or
        automatically.
      </p>
      <p>
        <em>
          <strong>Session cookies</strong>
        </em>
      </p>
      <p>
        Session cookies remain on your device until you close your browser. Once
        your browser is closed the cookies are automatically deleted.
      </p>
      <h2>What cookies we use and why we use them</h2>
      <p>
        <em>
          <strong>Essential cookies</strong>
        </em>
      </p>
      <p>
        Essential cookies are technical cookies that may be required for the
        operation of our site. Without essential cookies, our site may not
        operate properly.
      </p>
      <p>
        <em>
          <strong>Google Analytics</strong>
        </em>
      </p>
      <p>
        This type of cookie allows us to collect information about website
        visitors. This will help us understand which browser they have come from
        and where they drop off the site.
      </p>
      <p>
        <em>
          <strong>Google Adwords</strong>
        </em>
      </p>
      <p>
        These type of cookies explain to us how we acquire website traffic and
        from what website you have come from. This type of cookie will help us
        track marketing success and will add a cookie when a user clicks on an
        ad.
      </p>
      <p>
        <em>
          <strong>Facebook Pixels</strong>
        </em>
      </p>
      <p>
        We will use Facebook tracking pixels to have a better overview of our
        Facebook marketing activity. Facebook pixels take care of advertisement
        re marketing, optimization, and tracking all in one place to aid us in
        running successful and relevant marketing campaigns.
      </p>
      <p>
        <em>
          <strong>Perfomance cookies</strong>
        </em>
      </p>
      <p>
        Performance cookies allow us to recognise and count the number of
        visitors to our site and to see how visitors navigate around it. This
        helps us to improve the functioning of our site by allowing us to change
        the way visitors use them. The information we collect from performance
        cookies is then gathered and grouped which means that we cannot identify
        you from it.
      </p>
      <p>
        <em>
          <strong>Experience cookies</strong>
        </em>
      </p>
      <p>
        Experience cookies allow our site to remember the behaviour of users.
        Our site uses these cookies to provide you with enhanced and
        personalised features. For example, we use information gathered through
        what are known as &apos;web-analytic&apos; cookies to compare the
        choices you make to those of our other customers so that we can learn
        from those choices. Information collected by experience cookies cannot
        track your browsing activity when you leave our sites to browse other
        sites.
      </p>
      <p>
        <em>
          <strong>Marketing cookies</strong>
        </em>
      </p>
      <p>
        Marketing cookies will document your visits to our site, the pages you
        have viewed and the links you have followed. We then utilise this
        information to make our site and the content/advertising displayed to
        you more relevant to your interests. For example, we use marketing
        cookies to limit the number of times that you see adverts. Sometimes we
        share information about your browsing activity, (which we have collected
        from cookies) with our commercial/media partners. They may use this
        information to advertise products, which may interest you, on other
        sites.
      </p>
      <p>
        <em>
          <strong>
            Your right to refuse cookies and what happens if you refuse them
          </strong>
        </em>
      </p>
      <p>
        You can refuse cookies by activating the relevant setting on your
        browser. However, if you do so you may not be able to access all or
        parts of our site. If you continue using our site and do not change your
        browser settings we will assume you consent to us using cookies as
        described above.
      </p>
      <p>
        For more general information on cookies and how you can manage them
        visit
        <span>
          <a className="cookieLink" href="http://www.aboutcookies.org.uk/">
            www.aboutcookies.org.uk
          </a>
        </span>
      </p>
    </div>
  </>
);
export default Cookies;
