import Cookies from '@/templates/Cookies';
import React, { FC } from 'react';

import Layout from '../components/Layout/Layout';

const CookiePolicy: FC = () => (
  <div>
    <Layout>
      <Cookies />
    </Layout>
  </div>
);

export default CookiePolicy;
